<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterVideoShows">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <app-input
                v-model="filter.title"
                id="filter_title"
                :label="$t('videoShow.filter.title')"
              >
              </app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model="filter.id"
                id="filter_id"
                :label="$t('videoShow.filter.id')"
              >
              </app-input>
            </div>
            <div class="col-lg-4">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('videoShow.filter.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import VideoShowFilter from '../../model/VideoShowFilter'

export default {
  name: 'VideoShowFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(VideoShowFilter)
    }
  },
  components: {
    appInput: Input
  },
  methods: {
    filterVideoShows () {
      this.$store.commit('videoShow/setPage', 1)
      this.$store.commit('videoShow/setFilter', this.filter)
      this.$store.dispatch('videoShow/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(VideoShowFilter)
      this.$store.commit('videoShow/setFilter', this.filter)
      this.$store.dispatch('videoShow/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['videoShow/filter']
  }
}
</script>
